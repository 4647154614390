<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole minheight">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <div class="catalog-con">
        <div class="local-con" v-if="tukuShow">
          <div class="local-title">余姚各地图库分站</div>
          <div class="local-img-con">
            <div class="local-list" v-for="k in anhuiLocalList" :key="k.id">
              <div class="local-list-img" :style="{ backgroundImage: `url(` + k.ImgURL + `)` }" @click="onLocalFixtopic(k.id, k.name)"></div>
              <div class="local-list-text" @click="onLocalFixtopic(k.id, k.name)">
                {{ k.name }}
              </div>
            </div>
          </div>
          <div class="local-biao-con">
            <div class="local-biao-list" v-for="j in anhuiLocalList" :key="j.id" @click="onLocalFixtopic(j.id, j.name)">
              {{ j.name }}
            </div>
          </div>
        </div>
        <div class="catalog-list" v-for="(p, fixedIndex) in newsList" :key="p.id">
          <div class="catalog-title">
            <div class="title-left" @click="onCatalogMore(p.id)">
              {{ p.title }}
            </div>
            <div class="title-right" @click="onCatalogMore(p.id)">更多>></div>
          </div>
          <div
            class="cataloag-content"
            v-bind:class="{
              fixtopOne: p.typeOne,
              fixtopTwo: p.typeTwo,
              fixtopThree: p.typeThree,
            }"
          >
            <div class="catalog-detail" v-for="(k, index) in p.posts" :key="'nav_' + k.postId">
              <div class="video-img" v-if="k.posterShow" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @mouseenter="onNoposter(index, fixedIndex)"></div>
              <div class="img" v-if="!videoShow" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPost(k.postId)"></div>
              <video
                :src="k.videoURL"
                v-if="videoShow"
                :poster="k.imgURL"
                controls
                :ref="'video_' + index + '_' + p.id"
                @playing="onVideoStatusChange('video_' + index + '_' + p.id, $event)"
                loop
                width="876"
                height="523"
              ></video>
              <div class="title" @click="onLookPost(k.postId)" v-if="!videoShow">
                {{ k.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      newsList: [], // 新闻图片的所有
      catalogTitle: '',
      typeShow: false, // 展示什么图片
      typeflag: 1, // 栏目的类型
      typeflagtwo: 1,
      typethree: false, // 三类栏目的显示与隐藏
      tukuShow: false, // 图库的特别展示
      anhuiLocalList: [],
      videoShow: false, // 视频的显示
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('param', thiz.$route.query)
      thiz.catalogTitle = thiz.$route.query.title
      var catalogId = thiz.$route.query.id
      thiz.tukuShow = false
      thiz.videoShow = false

      if (thiz.$route.query.title == '栏目图库') {
        catalogId = 203
        thiz.getCatalogId(catalogId)
        thiz.tukuShow = true
        thiz.videoShow = false
        RD.addr()
          .id(12)
          .downAddress()
          .then((data) => {
            console.log('99', data)
            data.forEach((i) => {
              i.ImgURL = RD.addr().id(i.id).bgImage_URL()
            })
            thiz.anhuiLocalList = data
          })
      } else if (thiz.$route.query.title == '视频') {
        catalogId = 200
        thiz.getCatalogId(catalogId)
        thiz.tukuShow = false
        thiz.videoShow = true
      } else {
        this.getCatalogName()
        thiz.tukuShow = false
        thiz.videoShow = false
      }
    },
    getCatalogName() {
      var thiz = this
      RD.index()
        .catalogByName(thiz.$route.query.title)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts.forEach((k) => {
              if (thiz.videoShow) {
                k.imgURL = RD.video().id(k.postId).poster_URL()
                k.posterShow = true
                k.videoURL = RD.video().id(k.postId).svideo_URL()
              } else {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
            })
            var indexOrder = index + 1
            if (indexOrder % 3 == 1) {
              i.typeOne = true
              i.typeTwo = false
              i.typeThree = false
            } else if (indexOrder % 3 == 2) {
              i.typeTwo = true
              i.typeOne = false
              i.typeThree = false
            } else if (indexOrder % 3 == 0) {
              i.typeThree = true
              i.typeTwo = false
              i.typeOne = false
            }
          })
          thiz.newsList = data
        })
    },
    getCatalogId(catalogId) {
      var thiz = this
      RD.index()
        .catalog(catalogId)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts.forEach((k) => {
              if (thiz.videoShow) {
                k.imgURL = RD.video().id(k.postId).poster_URL()
                k.posterShow = true
                k.videoURL = RD.video().id(k.postId).svideo_URL()
              } else {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
            })
            var indexOrder = index + 1
            if (indexOrder % 3 == 1) {
              i.typeOne = true
              i.typeTwo = false
              i.typeThree = false
            } else if (indexOrder % 3 == 2) {
              i.typeTwo = true
              i.typeOne = false
              i.typeThree = false
            } else if (indexOrder % 3 == 0) {
              i.typeThree = true
              i.typeTwo = false
              i.typeOne = false
            }
          })
          thiz.newsList = data
        })
    },
    // 更多
    onCatalogMore(topicId) {
      this.$router.push({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this
      console.log('event', event)
      console.log('ref', thiz.$refs)
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == 'video_') {
          if (currentplay == key) {
            // 当前正在播放的
            console.log('currentplay')
          } else {
            // 其他的都暂停
            console.log('sibliingpause', thiz.$refs, thiz.$refs[key])
            thiz.$refs[key][0].pause()
          }
        }
      }
    },
    // 城市的fixtopic
    onLocalFixtopic(id, type) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: id, name: type },
      })
      window.open(routerUrl.href, '_blank')
    },
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 鼠标移入
    onNoposter(index, fixedIndex) {
      this.newsList[fixedIndex].posts[index].posterShow = false
    },
  },
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  .catalog-whole {
    height: auto;
    background-color: #fff;
    min-height: 1000px;
    width: 750px;
    .catalogtitle {
      height: 40px;
      text-align: left;
      width: 750px;
      margin: 0 auto;
      font-size: 16px;
      padding-top: 15px;
      display: flex;
      border-bottom: 0.1rem solid #bfbfbf;
      .icon {
        width: 20px;
        height: 20px;
        background: no-repeat url(../assets/weizhi.png);
        background-size: cover;
        margin-right: 10px;
      }
    }
    .catalog-con {
      width: 750px;
      margin: 20px auto;
      height: 100%;
      overflow: hidden;
      .local-con {
        height: auto;
        overflow: hidden;
        margin-bottom: 30px;
        .local-title {
          height: 40px;
          line-height: 40px;
          font-size: 20px;
        }
        .local-img-con {
          width: 750px;
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .local-list {
            width: 171px;
            height: 182px;
            margin-right: 12px;
            .local-list-img {
              height: 80%;
              width: 100%;
              background: no-repeat 50% / cover;
              cursor: pointer;
            }
            .local-list-text {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        .local-biao-con {
          height: auto;
          width: 750px;
          display: flex;
          flex-wrap: wrap;
          margin-top: 40px;
          .local-biao-list {
            width: 115px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #f5f5f5;
            margin: 5px;
            font-size: 14px;
            cursor: pointer;
          }
          .local-biao-list:hover {
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
          }
        }
      }
      .catalog-list {
        height: auto;
        width: 100%;
        margin-bottom: 30px;
        background-color: #fff;

        .catalog-title {
          height: 65px;
          font-size: 14px;
          line-height: 65px;
          text-align: left;
          font-weight: bold;
          position: relative;
          background-color: #f5f5f5;
          .title-left {
            position: absolute;
            left: 46%;
            width: auto;
            font-size: 22px;
            cursor: pointer;
          }
          .title-right {
            position: absolute;
            right: 30px;
            top: 0rem;
            width: 60px;
            cursor: pointer;
            color: #9b9b9b;
            .el-icon-right {
              font-size: 20px;
            }
          }
        }
        .cataloag-content {
          position: relative;
          height: 273px;
          .catalog-detail {
            overflow: hidden;
            .img {
              transition: all 200ms ease-in-out;
              cursor: pointer;
            }
            .title {
              opacity: 0.8;
              transition: all 200ms ease-in-out;
              font-size: 14px;
              background-color: rgba(0, 0, 0, 0.6);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding: 0rem 1rem;
              height: 40px;
              line-height: 40px;
              width: calc(100% - 2rem);
              position: absolute;
              bottom: 0rem;
              left: 0rem;
              color: #fff;
              cursor: pointer;
            }
          }
          .catalog-detail:hover {
            .img {
              transform: scale(1.05);
            }
            .title {
              opacity: 1;
              font-size: 16px;
            }
          }
          .catalog-detail {
            background: #000;
            position: relative;
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
            }
            .video-img {
              position: absolute;
              top: 0rem;
              left: 0rem;
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
              z-index: 2000;
            }
          }
        }
        .fixtopOne {
          .catalog-detail:nth-child(1) {
            width: 362px;
            height: 267px;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 0rem;
            left: 369px;
          }
          .catalog-detail:nth-child(3) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 0rem;
            left: 552px;
          }
          .catalog-detail:nth-child(4) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 137px;
            left: 369px;
          }
          .catalog-detail:nth-child(5) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 137px;
            left: 552px;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 27.6rem;
            height: 20rem;
            position: absolute;
            top: 22.5rem;
            left: 192.4rem;
          }
        }
        .fixtopTwo {
          display: flex;
          flex-wrap: wrap;
          .catalog-detail {
            width: 177px;
            height: 127px;
            position: relative;
            margin-right: 10px;
          }
        }
        .fixtopThree {
          .catalog-detail:nth-child(1) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 0rem;
            left: 184px;
          }
          .catalog-detail:nth-child(3) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 137px;
            left: 0rem;
          }
          .catalog-detail:nth-child(4) {
            width: 177px;
            height: 131px;
            position: absolute;
            top: 137px;
            left: 184px;
          }
          .catalog-detail:nth-child(5) {
            width: 362px;
            height: 267px;
            position: absolute;
            top: 0rem;
            left: 367px;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 57.1rem;
            height: 45rem;
            position: absolute;
            top: 0rem;
            left: 162.8rem;
          }
        }
      }
    }
    .shuhua-con {
      width: 120rem;
      margin: 3rem auto;
      height: 100%;
      overflow: hidden;
      .shuahu-swiper {
        height: 56rem;
        width: 100%;
        .shuhua-swiper-list {
          height: 100%;
          width: 250%;
          display: flex;
          .shuhua-swiper-del {
            width: 120rem;
            height: 100%;
            .shuhua-swiper-img {
              width: 120rem;
              height: 100%;
              background: no-repeat 50% / cover;
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .catalog-whole {
    height: auto;
    background-color: #fff;
    min-height: 61.6rem;
    .catalogtitle {
      height: 4rem;
      text-align: left;
      width: 120rem;
      margin: 0 auto;
      font-size: 1.6rem;
      padding-top: 1.5rem;
      display: flex;
      border-bottom: 0.1rem solid #bfbfbf;
      .icon {
        width: 2rem;
        height: 2rem;
        background: no-repeat url(../assets/weizhi.png);
        background-size: cover;
        margin-right: 1rem;
      }
    }
    .catalog-con {
      width: 120rem;
      margin: 3rem auto;
      height: 100%;
      overflow: hidden;
      .local-con {
        height: auto;
        overflow: hidden;
        margin-bottom: 5rem;
        .local-title {
          height: 4rem;
          line-height: 4rem;
          font-size: 2.5rem;
        }
        .local-img-con {
          width: 240rem;
          margin-top: 2rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .local-list {
            width: 28.3rem;
            height: 30rem;
            margin-right: 1.7rem;
            .local-list-img {
              height: 80%;
              width: 100%;
              background: no-repeat 50% / cover;
              cursor: pointer;
            }
            .local-list-text {
              height: 4rem;
              line-height: 4rem;
              font-size: 1.5rem;
              cursor: pointer;
            }
          }
        }
        .local-biao-con {
          height: auto;
          width: 120rem;
          display: flex;
          flex-wrap: wrap;
          margin-top: 4rem;
          .local-biao-list {
            width: 19rem;
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            background: #f5f5f5;
            margin: 0.5rem;
            font-size: 1.4rem;
            cursor: pointer;
          }
          .local-biao-list:hover {
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
          }
        }
      }
      .catalog-list {
        height: auto;
        width: 100%;
        margin-bottom: 3rem;
        background-color: #fff;

        .catalog-title {
          height: 10rem;
          font-size: 1.3rem;
          line-height: 10rem;
          text-align: left;
          font-weight: bold;
          position: relative;
          background-color: #f5f5f5;
          .title-left {
            position: absolute;
            left: 46%;
            width: auto;
            font-size: 2rem;
            cursor: pointer;
          }
          .title-right {
            position: absolute;
            right: 3.4rem;
            top: 0rem;
            width: 6.7rem;
            cursor: pointer;
            color: #9b9b9b;
            .el-icon-right {
              font-size: 2.8rem;
            }
          }
        }
        .cataloag-content {
          position: relative;
          height: 45rem;
          .catalog-detail {
            overflow: hidden;
            .img {
              transition: all 200ms ease-in-out;
              cursor: pointer;
            }
            .title {
              opacity: 0.8;
              transition: all 200ms ease-in-out;
              font-size: 1.2rem;
              background-color: rgba(0, 0, 0, 0.6);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding: 0rem 1rem;
              height: 4rem;
              line-height: 4rem;
              width: calc(100% - 2rem);
              position: absolute;
              bottom: 0rem;
              left: 0rem;
              color: #fff;
              cursor: pointer;
            }
          }
          .catalog-detail:hover {
            .img {
              transform: scale(1.05);
            }
            .title {
              opacity: 1;
              font-size: 1.6rem;
            }
          }
          .catalog-detail {
            background: #000;
            position: relative;
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
            }
            .video-img {
              position: absolute;
              top: 0rem;
              left: 0rem;
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
              z-index: 2000;
            }
          }
        }
        .fixtopOne {
          .catalog-detail:nth-child(1) {
            width: 59.6rem;
            height: 44.1rem;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 60.5rem;
          }
          .catalog-detail:nth-child(3) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 90.8rem;
          }
          .catalog-detail:nth-child(4) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.5rem;
            left: 60.5rem;
          }
          .catalog-detail:nth-child(5) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.5rem;
            left: 90.8rem;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 27.6rem;
            height: 20rem;
            position: absolute;
            top: 22.5rem;
            left: 192.4rem;
          }
        }
        .fixtopTwo {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .catalog-detail {
            width: 29.3rem;
            height: 21.4rem;
            position: relative;
          }
        }
        .fixtopThree {
          .catalog-detail:nth-child(1) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 30.3rem;
          }
          .catalog-detail:nth-child(3) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.6rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(4) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.6rem;
            left: 30.3rem;
          }
          .catalog-detail:nth-child(5) {
            width: 59.6rem;
            height: 44.1rem;
            position: absolute;
            top: 0rem;
            left: 60.6rem;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 57.1rem;
            height: 45rem;
            position: absolute;
            top: 0rem;
            left: 162.8rem;
          }
        }
      }
    }
    .shuhua-con {
      width: 120rem;
      margin: 3rem auto;
      height: 100%;
      overflow: hidden;
      .shuahu-swiper {
        height: 56rem;
        width: 100%;
        .shuhua-swiper-list {
          height: 100%;
          width: 250%;
          display: flex;
          .shuhua-swiper-del {
            width: 120rem;
            height: 100%;
            .shuhua-swiper-img {
              width: 120rem;
              height: 100%;
              background: no-repeat 50% / cover;
            }
          }
        }
      }
    }
  }
}
</style>
